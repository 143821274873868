import "./scss/main.scss";
import Route from "./routes/Route";


function App() {
  return (
    <Route/>
  ) ;
}

export default App;
