// --------- import external dependencies ----------
import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 
// import Contact from "../screens/Contact";

// --------- import screen component with lazy load ----------
const Home = lazy(() => import("../screens/Home"));
const About = lazy(() => import("../screens/About"));
const Earth = lazy(() => import("../screens/Earth"));
const Contact = lazy(() => import("../screens/Contact"));






function AppRoute() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <Routes>
             <Route exact path="/" element={<Home />} /> 
           <Route exact path="/about" element={<About/>} />
          <Route exact path="/earth" element={<Earth/>} /> 
              <Route exact path="/contact" element={<Contact/>} /> 
              {/*
             <Route exact path="/dashboard" component={Dashboard} />  */}
            {/*<Route exact path="/music" component={Music} />  */}
  
  
  
            
          </Routes>
        </Router>
      </Suspense>
  
  
    );
  }
  
  export default AppRoute;